import React, { Component, ErrorInfo, ReactNode } from "react";
import { insights } from "../app-insights";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error,
  errorInfo?: ErrorInfo
}



class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {

    console.error("Uncaught error:", error, errorInfo);
    
    insights.trackException({
      severityLevel: 4,
      exception: error,      
    })
    
    this.setState({
        error,
        errorInfo
    })
  }

  public render() {
    if (this.state.hasError) {
      return (<div style={{display: "flex"}}>
        <h1>Sorry.. there was an error</h1>
        <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
        </div>);
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
