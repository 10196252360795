import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { globalHistory } from "@reach/router"
import { ComponentType, FunctionComponent } from 'react';

/*
if (!("XMLHttpRequest" in global)) {
    global.XMLHttpRequest = undefined;
    global.XDomainRequest = undefined;
}*/

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.GATSBY_APPLICATION_INSIGHTS_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: globalHistory }
        }
    }  
})

if (process.env.GATSBY_APPLICATION_INSIGHTS_KEY) {
    ai.loadAppInsights();
} else {
    console.log('Application insights key not available.')
}

export default (Component: ComponentType<unknown> | ComponentType<any>) => withAITracking(reactPlugin, Component)  // HOC
export const insights = ai.appInsights // Logger 
