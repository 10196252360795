const meli_client_id = process.env.GATSBY_MELI_CLIENT_ID ?? "20119399900552"

const shopify_client_id = "97cbd95d9d855f92a25a89af87dca08d"

const meli_redirect_path = "/auth/meli/"
const identityApiUrl =
    process.env.GATSBY_IDENTITY_API_URL ?? "https://accounts.fenicia.io"
const apiUrl = process.env.GATSBY_API_URL ?? "https://api.fenicia.io"
//const apiUrl = "http://localhost:3001"
const contentApiUrl =
    process.env.GATSBY_CONTENT_API_URL ?? "https://content.fenicia.io"
const googleApiUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_API_KEY}&libraries=places`

const hostUri = process.env.GASBY_HOST_URI ?? "http://localhost:8000"

const environment = process.env.GATSBY_ENVIRONMENT ?? "prod"

const  amazon_seller_central_app_id = process.env.GATSBY_AMAZON_SELLER_CENTRAL_CLIENT_ID
const mercado_libre_endpoint = "https://api.mercadolibre.com"

export class ClientCredentiasls {
    client_id: string    
    redirect_path:string

    
    constructor(client_id : string, redirect_path:string) {
        this.client_id = client_id        
        this.redirect_path = redirect_path
    }
}

const meliCredentials = new ClientCredentiasls(
    meli_client_id,  
    meli_redirect_path
)

export { meliCredentials, identityApiUrl, apiUrl, contentApiUrl, shopify_client_id, googleApiUrl , environment,mercado_libre_endpoint ,amazon_seller_central_app_id, hostUri}
