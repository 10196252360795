import * as localForage from "localforage";

localForage.config({
    name:"fenicia-db"
})



const database = () =>  {



    const get = async(key: string) => {
        return await localForage.getItem(key)
    }
    const save = async (key:string, value: any) => {
        return await  localForage.setItem(key, value)
    }
    const remove = async (key:string)=> {
        return await localForage.removeItem(key)
    }

    return {
        get,
        save, 
        remove
    }
 }


export default database()
