import React from "react"
import {User} from "firebase/auth"

interface FeniciaContextType {
    theme?: string, 
    tenant?: string, 
    location?: string, 
    user?: User, 
    accountSession?: AccountSessionInfo,
    apiToken?: string, 
    setApiToken : (token?:string) => void,
    setLocation : (location?:string) => void, 
    updateToken: (token?:string) => void,
    refreshToken?: (token?:string) => void, 
    setTheme: (theme?:string) => void, 
    setTenant: (tenant?:string) => void, 
    setUser: (user?:User)  => void,
    setAccountSessionInfo: (accountSessionInfo?: AccountSessionInfo) => void
}

let context : FeniciaContextType = {
    theme: "light",
    tenant: "default", 
    location: "online-store", 
    user: undefined,
    accountSession: undefined, 
    apiToken: undefined,
    setApiToken: () => {}, 
    setLocation: () => {},
    updateToken: () => {},
    refreshToken: () => {},
    setTheme: () => {},
    setTenant: () => {},
    setUser : () => {},
    setAccountSessionInfo: () => {}
}

const FeniciaContext = React.createContext<FeniciaContextType>(context)

export default FeniciaContext


