import { identityApi } from "./identityApi"

const getIdentityToken = async (idToken: string, tenant: string) => {
    if (tenant) {
        let response = await identityApi.post("/token", { tenant }, {
            headers: {
                "Authorization": `Bearer ${idToken}`,
                "Access-Control-Allow-Origin": "*"
            }
        })

        if (response.status === 200) {
            console.log("success call to token")
            return response.data;
        }
        else {
            console.log(response)
        }
    }
}


const getAccountInfo = async (idToken: string, tenant: string) => {
    if (tenant) {
        let response = await identityApi.get("/session/" + tenant, {
            headers: {
                "Authorization": `Bearer ${idToken}`,
                "Access-Control-Allow-Origin": "*"
            }
        })
        return response;
    }
}

const checkEmailAvailability = async (email: string) => {
    return await identityApi.get(`/users/email/${email}`)
}

const createUser = async (key: string, userInfo: any) => {
    let data = {
        key,
        account: userInfo
    }
    return await identityApi.post("/users", data)
}


const generateCodeVerification = (user: any) => {
    let data = {
        email: user.email
    };
    return identityApi.post("/verification/generate-code", data);
}

const verifyCodeVerification = (user: any) => {
    let data = {
        email: user.email,
        code: user.code
    };
    return identityApi.post("/verification/verify-code", data);
}

const removeCodeVerification = (user:any) => {
    let data = {
        email: user.email,
        code: user.code
    };
    return identityApi.delete("/verification/finish-verify", {
        data: { ...data }
    })
}

export {
    getIdentityToken,
    createUser,
    checkEmailAvailability,
    getAccountInfo,
    generateCodeVerification,
    verifyCodeVerification,
    removeCodeVerification
}