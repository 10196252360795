import * as React from "react"
import database from "../services/localDatabase"
import FeniciaContext from "."
import { getIdentityToken } from "../services/authentication/fenicia-identity";
import {User} from "firebase/auth"

const FeniciaContextProvider = ({children}: {children:React.ReactNode}) => {

    const [tenant,setTenant]  = React.useState<string| undefined>()
    const [location, setLocation] = React.useState<string|undefined>()
    const [apiToken, setApiToken] = React.useState<string| undefined>()
    const [user, setUser] = React.useState<User>()
    const [theme, setTheme] = React.useState<string| undefined>("light")
    const [accountSession, setAccountSessionInfo ] = React.useState<AccountSessionInfo|undefined>()

    
    const updateToken = async () => {
        console.log("Update token requested")
        if(user)
        {
            let token = await user.getIdToken(true)
            let identityToken = await getIdentityToken(token,tenant ?? "")
            setApiToken(identityToken)
        }                
    }

    const changeTheme = async (theme?:string ) => {
        setTheme(theme)
    }

    const locationChange = (location?:string) => {
        setLocation(location)
    }
    const apiTokenChange =(token?:string) => {
        setApiToken(token)
    }
    const changeTenant = (tenant?: string) => {    
        
        database.save("currentTenant", tenant)       
        setTenant(tenant)
    }

    
    return (<FeniciaContext.Provider value={{
        apiToken,        
        tenant,
        user,
        theme,
        location, 
        accountSession,
        setLocation: locationChange, 
        setApiToken:apiTokenChange,
        updateToken, 
        setTheme: changeTheme,
        setUser,
        setAccountSessionInfo,
        setTenant: changeTenant
    }}> 
        {children}
    </FeniciaContext.Provider>)
}
export default FeniciaContextProvider